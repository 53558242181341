/** @format */

import { i18nContext } from '@/context/i18nContext';
import { FunctionComponent as FC } from 'react';
import styled from 'styled-components';
import { StyledButton } from '../ui/Button';
import { Subtitle } from '../ui/Typography';

export const NoResults: FC<Props> = ({ reset }) => {
  return (
    <i18nContext.Consumer>
      {t => (
        <Container>
          <Subtitle as="p">{t.news['no-results']}</Subtitle>
          <StyledButton elementType="button" onClick={reset} variant="bgTeal" position="none">
            {t.news['reset-filters']}
          </StyledButton>
        </Container>
      )}
    </i18nContext.Consumer>
  );
};

const Container = styled.div`
  & > * + * {
    margin-top: 1em;
  }

  p {
    white-space: pre-line;
    color: ${({ theme }) => theme.color.teal.dark};
  }
`;

type Props = {
  reset: () => void;
};
