/** @format */

import { HeaderSmall } from '@/components/Header';
import {
  AboutUsHero,
  DesignBuildHero,
  RealEstateInvestmentsHero,
  RealEstateManagementHero,
  WorkWithUsHero,
} from '@/components/Hero';
import { PageWrapper } from '@/components/PageWrapper';
import { useLanguageSwitcherContext } from '@/context/LanguageSwitcherContext';
import { usePageContent } from '@/hooks/usePageContent';
import { CommonPageProps, GenericPageProps } from '@/types/pageprops';
import dynamic from 'next/dynamic';
import { FunctionComponent as FC, useEffect, useState } from 'react';
import { Loader } from '../components/ui/Loader';

const PageContent = dynamic(() => import('@/components/PageContent').then(mod => mod.PageContent), {
  loading: () => <Loader />,
});

export const GenericPage: FC<Props> = ({ page, navigation, footer, company, translations, allSlugs }) => {
  const pageContent = usePageContent(page);
  const [pageTitle, setPageTitle] = useState(page.title);
  const { setSlugs } = useLanguageSwitcherContext();

  useEffect(() => {
    setSlugs(allSlugs.slug);
  }, [allSlugs]);
  useEffect(() => {
    setPageTitle(page.title);
  }, [page.title]);

  return (
    <PageWrapper
      meta={page.seo.fields}
      pageType={page.pageType}
      navigation={navigation}
      footer={footer}
      company={company}
      translations={translations}
      slug={page.slug.fields.slug}
    >
      {!(page.body || page.heroImage || page.image) && <HeaderSmall title={page.title} />}

      {page.body && (page.image || page.heroImage) && (
        <>
          {page.heroImageType === 'about-us' && (
            <AboutUsHero title={page.body} name={page.title} image={page.image} background={page.heroBackgroundColor} />
          )}
          {page.heroImageType === 'real-estate-management' && (
            <RealEstateManagementHero
              title={page.body}
              name={page.title}
              image={page.image}
              background={page.heroBackgroundColor}
            />
          )}
          {page.heroImageType === 'work-with-us' && (
            <WorkWithUsHero
              title={page.body}
              name={page.title}
              image={page.image}
              background={page.heroBackgroundColor}
            />
          )}
          {page.heroImageType === 'design-build' && (
            <DesignBuildHero
              title={page.body}
              name={page.title}
              image={page.image}
              background={page.heroBackgroundColor}
            />
          )}
          {page.heroImageType === 'real-estate-investments' && (
            <RealEstateInvestmentsHero
              title={page.body}
              name={page.title}
              image={page.image}
              background={page.heroBackgroundColor}
            />
          )}
        </>
      )}

      <PageContent content={pageContent} title={pageTitle} />
    </PageWrapper>
  );
};
type Props = CommonPageProps & GenericPageProps;
