/** @format */

import dynamic from 'next/dynamic';
import { FunctionComponent as FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useMediaQueryIndex } from '../../hooks/useMediaQuery';
import { breakpointFrom } from '../../theme/mixins';
import { BlogPostCard } from '../../types/news';
import { Loader } from '../ui/Loader';
import { Card } from './Card';
import { NoResults } from './NoResults';
import { Pagination } from './Pagination';

const SearchBarMobile = dynamic(() => import('./SearchBar').then(mod => mod.SearchBarMobile), {
  loading: () => <Loader />,
});

const SearchBarDesktop = dynamic(() => import('./SearchBar/SearchBarDesktop').then(mod => mod.SearchBarDesktop), {
  loading: () => <Loader />,
});

const POSTS_PER_PAGE = 6;

export const BlogSection: FC<Props> = ({ posts }) => {
  const mediaQueryIndex = useMediaQueryIndex();

  const mobile = useMemo(() => !!(mediaQueryIndex !== null && mediaQueryIndex < 2), [mediaQueryIndex]);
  const desktop = useMemo(() => !!(mediaQueryIndex !== null && mediaQueryIndex >= 2), [mediaQueryIndex]);

  const [itemOffset, setItemOffset] = useState(0);
  const [filteredPosts, setFilteredPosts] = useState([...posts]);

  const endOffset = itemOffset + POSTS_PER_PAGE;
  const currentPosts = filteredPosts.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(filteredPosts.length / POSTS_PER_PAGE);

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * POSTS_PER_PAGE) % filteredPosts.length;
    setItemOffset(newOffset);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const blogCategories = useMemo(() => {
    const allCategories = Array.from(new Set(posts.map(post => post.category)));
    return allCategories.sort();
  }, [posts]);

  const blogYears = useMemo(() => {
    const allYears = Array.from(new Set(posts.map(post => new Date(post.date).getFullYear())));
    return allYears.sort((a, b) => b - a);
  }, [posts]);

  const resetFilters = () => {
    setFilteredPosts([...posts]);
  };

  useEffect(() => resetFilters(), [posts]);

  return (
    <>
      <FullRow>
        {mobile && (
          <SearchBarMobile
            categories={blogCategories}
            years={blogYears}
            setFilteredPosts={setFilteredPosts}
            allPosts={posts}
          />
        )}
        {mobile && <Pagination onPageChange={handlePageClick} pageCount={pageCount} mobile />}
        {desktop && (
          <SearchBarDesktop
            categories={blogCategories}
            years={blogYears}
            setFilteredPosts={setFilteredPosts}
            allPosts={posts}
          />
        )}
      </FullRow>
      <div>
        {currentPosts.length > 0 && (
          <Blog>
            {currentPosts.map((post, i) => post.slug && <Card key={`${post.slug.fields.slug}-${i}`} post={post} />)}
          </Blog>
        )}
        {currentPosts.length === 0 && <NoResults reset={resetFilters} />}
        {desktop && <Pagination onPageChange={handlePageClick} pageCount={pageCount} />}
      </div>
    </>
  );
};

type Props = {
  posts: BlogPostCard[];
};

const FullRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-column: 1/-1;

  margin-bottom: clamp(2.5rem, 1.6667rem + 3.7037vw, 5rem);
`;

const Blog = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
  gap: 1.85rem;

  @media (min-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  ${breakpointFrom.md`
    margin-right: 1rem;
  `}
`;
