/** @format */

import { NewsContent } from '@/components/Blog/NewsContent';
import { HeaderLarge, HeaderSmall } from '@/components/Header';
import { PageWrapper } from '@/components/PageWrapper';
import { useLanguageSwitcherContext } from '@/context/LanguageSwitcherContext';
import { usePageContent } from '@/hooks/usePageContent';
import { CommonPageProps, NewsPageProps } from '@/types/pageprops';
import { FunctionComponent as FC, Fragment, useEffect } from 'react';

export const News: FC<Props> = ({
  page,
  footer,
  navigation,
  translations,
  company,
  allBlogPosts,
  allNews,
  allSlugs,
}) => {
  const { setSlugs } = useLanguageSwitcherContext();
  const pageContent = usePageContent(page);

  useEffect(() => {
    setSlugs(allSlugs.slug);
  }, [allSlugs]);

  return (
    <PageWrapper
      meta={page.seo.fields}
      pageType="news"
      navigation={navigation}
      footer={footer}
      company={company}
      translations={translations}
      slug={page.slug.fields.slug}
    >
      {!(page.body || page.heroImage) && <HeaderSmall title={page.title} />}
      {page.body && page.heroImage && (
        <HeaderLarge
          pageName={page.title}
          title={page.body}
          image={page.heroImage}
          mobileImage={page.heroImageMobile}
        />
      )}
      {pageContent.map(({ id, fields }, i) => (
        <Fragment key={`${id}-${i}`}>
          {id === 'newsContent' && (
            <NewsContent label={fields.asideLabel} blogPosts={allBlogPosts} newsArticles={allNews} />
          )}
        </Fragment>
      ))}
    </PageWrapper>
  );
};

type Props = CommonPageProps & NewsPageProps;
