/** @format */

import { FunctionComponent as FC, useMemo } from 'react';
import ReactPaginate from 'react-paginate';

export const Pagination: FC<Props> = ({ pageCount, onPageChange, mobile = false, centered = false }) => {
  const containerClassList = useMemo(() => {
    const classes = [];
    if (mobile) classes.push('mobile');
    else classes.push('desktop');

    if (centered) classes.push('centered');

    return classes.join(' ');
  }, [mobile, centered]);

  return (
    <>
      {pageCount > 1 && (
        <ReactPaginate
          breakLabel="..."
          onPageChange={onPageChange}
          pageRangeDisplayed={4}
          marginPagesDisplayed={1}
          pageCount={pageCount}
          renderOnZeroPageCount={null}
          nextClassName="controls"
          previousClassName="controls"
          activeLinkClassName="active"
          containerClassName={containerClassList}
        />
      )}
    </>
  );
};
type Props = {
  pageCount: number;
  onPageChange: (selectedItem: { selected: number }) => void;
  mobile?: boolean;
  centered?: boolean;
};
