/** @format */

import { FunctionComponent as FC, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Container, ContainerWrapper } from '../ui/Layout';
import { SmallH1 } from '../ui/Typography';

export const HeaderSmall: FC<Props> = ({ title }) => {
  const theme = useContext(ThemeContext);
  const darkTeal = theme!.color.teal.dark;
  return (
    <ContainerWrapper $color={theme!.color.yellow} as="section">
      <Wrapper>
        <SmallH1 color={darkTeal}>{title}</SmallH1>
      </Wrapper>
    </ContainerWrapper>
  );
};

type Props = {
  title: string;
};

const Wrapper = styled(Container)`
  padding: clamp(1rem, -0.0833rem + 4.8148vw, 4.25rem) 0;
`;
