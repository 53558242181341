/** @format */

import { PrefixContext, prefixContext } from '@/context/prefixContext';
import {
  getAllBlogPosts,
  getAllNews,
  getCategoryBlogPosts,
  getCompany,
  getFooter,
  getNavigation,
  getNewsSlug,
  getNewsSlugsById,
  getPage,
  getSlugs,
  getTranslations,
} from '@/lib/contenful.api';
import { utilityGetPrefixes } from '@/lib/formvalidations.api';
import { getAllArticleSlugs, getAllNewsSlugs, getAllPageSlugs, getPageType } from '@/lib/page-generation';
import { BlogPost } from '@/types/news';
import { PageType } from '@/types/page';
import { ArticlePageProps, CommonPageProps, GenericPageProps, NewsPageProps } from '@/types/pageprops';
import { Article } from '@/views/Article';
import { GenericPage } from '@/views/GenericPage';
import { News } from '@/views/News';
import { GetStaticPaths, GetStaticProps, NextPage } from 'next';

const Page: NextPage<PageProps> = ({
  page,
  allSlugs,
  navigation,
  footer,
  company,
  translations,
  allNews,
  allBlogPosts,
  categoryBlogPosts,
  prefixes,
  countries,
  newsSlugs,
}) => {
  if (page.pageType === 'newsPage')
    return (
      <News
        page={page as PageType}
        navigation={navigation}
        footer={footer}
        company={company}
        allNews={allNews!}
        allBlogPosts={allBlogPosts!}
        allSlugs={allSlugs!}
        translations={translations}
      />
    );
  if (page.pageType === 'postBlog')
    return (
      <Article
        page={page as BlogPost}
        navigation={navigation}
        footer={footer}
        company={company}
        translations={translations}
        newsSlugs={newsSlugs!}
        categoryBlogPosts={categoryBlogPosts!}
      />
    );
  return (
    <PrefixContext.Provider value={prefixes || prefixContext}>
      <GenericPage
        page={page as PageType}
        navigation={navigation}
        footer={footer}
        company={company}
        translations={translations}
        allSlugs={allSlugs!}
      />
    </PrefixContext.Provider>
  );
};

export default Page;

export const getStaticProps: GetStaticProps = async ({ locale = 'en', params }) => {
  const localeToFetch = () => {
    if (locale === 'default') return 'en';
    return locale;
  };

  const slugArr = params!.slug! as string[];
  const newsSlug = await getNewsSlug(locale);
  const pageType = getPageType(slugArr, newsSlug!);

  const slug = pageType === 'postBlog' ? slugArr[slugArr.length - 1] : slugArr.join('/');
  const navigation = await getNavigation(localeToFetch());
  const footer = await getFooter(localeToFetch());
  const company = await getCompany(localeToFetch());
  const translations = await getTranslations(localeToFetch());

  if (pageType === 'postBlog') {
    const page: BlogPost = await getPage(pageType, localeToFetch(), slug);
    const categoryBlogPosts = page && (await getCategoryBlogPosts(localeToFetch(), page.category, page.Id));
    const newsSlugs = await getAllNewsSlugs();

    return {
      notFound: page ? page.language !== locale : true,
      props: {
        key: slug,
        page: { ...page, pageType: pageType },
        categoryBlogPosts,
        navigation,
        footer,
        company,
        translations,
        newsSlugs: newsSlugs[0].fields.slug,
      },
    };
  }

  if (pageType === 'newsPage') {
    const page: PageType = await getPage(pageType, localeToFetch(), slug);
    const allNews = await getAllNews(localeToFetch());
    const allBlogPosts = await getAllBlogPosts(localeToFetch());
    const allSlugs = await getNewsSlugsById(page.slug.sys.id);

    return {
      notFound: locale === 'default',
      props: {
        key: slug,
        page: { ...page, pageType: pageType },
        allNews,
        allBlogPosts,
        allSlugs,
        navigation,
        footer,
        company,
        translations,
      },
    };
  }

  const page: PageType = await getPage(pageType, localeToFetch(), slug);
  const allSlugs = await getSlugs(page.slug.sys.id);

  const prefixes = await utilityGetPrefixes(localeToFetch() == 'it' ? localeToFetch() : 'en')
    .then(res => {
      return res;
    })
    .catch(e => {
      return e;
    });

  return {
    notFound: locale === 'default',
    props: {
      key: slug,
      page,
      allSlugs,
      navigation,
      footer,
      company,
      translations,
      prefixes,
    },
  };
};

export const getStaticPaths: GetStaticPaths = async ({ locales }) => {
  const languages = locales!.filter(lang => lang !== 'default');

  const pageSlugs = await getAllPageSlugs();
  const newsSlugs = await getAllNewsSlugs();
  const articleSlugs = await getAllArticleSlugs();

  const allPaths = [pageSlugs, newsSlugs, articleSlugs].flat();

  let paths: Paths = [];

  allPaths.map(item => {
    languages.map(locale => {
      paths.push({
        params: {
          slug:
            item.sys.contentType.sys.id === 'articleSlug'
              ? `${newsSlugs[0].fields.slug[locale]}/${item.fields.slug[locale]}`.split('/')
              : item.fields.slug[locale].split('/'),
        },
        locale,
      });
    });
  });

  return {
    paths,
    fallback: false,
  };
};

type Paths = {
  params: { slug: string[] };
  locale: string;
}[];

type PageProps = CommonPageProps & (ArticlePageProps | NewsPageProps | GenericPageProps);
