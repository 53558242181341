/** @format */

import { FunctionComponent as FC, useContext, useMemo } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { breakpointFrom } from '../../theme/mixins';
import { BlogPostCard, NewsCard } from '../../types/news';
import { sortDatesDescending } from '../../utils/sortDates';
import { Container, ContainerWrapper } from '../ui/Layout';
import { BlogSection } from './BlogSection';
import { NewsSection } from './NewsSection';

export const NewsContent: FC<Props> = ({ blogPosts, newsArticles, label }) => {
  const theme = useContext(ThemeContext);

  const orderedBlogPosts = useMemo(() => blogPosts.sort((a, b) => sortDatesDescending(a.date, b.date)), [blogPosts]);

  const orderedNewsArticles = useMemo(
    () => newsArticles.sort((a, b) => sortDatesDescending(a.date, b.date)),
    [newsArticles],
  );

  return (
    <ContainerWrapper as="section" $color={theme?.color.white}>
      <Grid>
        <BlogSection posts={orderedBlogPosts} />
        <NewsSection articles={orderedNewsArticles} label={label} />
      </Grid>
    </ContainerWrapper>
  );
};

type Props = {
  label?: string;
  blogPosts: BlogPostCard[];
  newsArticles: NewsCard[];
};

const Grid = styled(Container)`
  display: grid;
  grid-template-columns: minmax(0, 1fr);

  ${breakpointFrom.md`
    grid-template-columns: minmax(0, 2fr) minmax(20rem, 1fr);
  `}

  padding-block: 1.85rem;

  .search {
    grid-column: 1/-1;
  }

  // #region PAGINATION STYLES
  .controls {
    display: none;
  }

  ul {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 0.62rem;

    width: max-content;
  }

  .mobile,
  .desktop {
    grid-column: 1/-1;
  }

  .desktop {
    margin-top: 3.5rem;
  }

  .centered {
    margin-inline: auto;
  }

  li {
    a {
      color: ${({ theme }) => theme.color.teal.dark};
      cursor: pointer;
    }
    a.active,
    a:hover,
    a:active,
    a:focus-visible {
      color: ${({ theme }) => theme.color.pink};
    }
  }
  // #endregion
`;
