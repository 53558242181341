/** @format */

import { useRouter } from 'next/router';
import { FunctionComponent as FC, useMemo } from 'react';
import styled from 'styled-components';
import { breakpointFrom } from '../../theme/mixins';
import { NewsCard } from '../../types/news';
import { Image } from '../Image';
import { OutboundLink } from '../ui/Link';
import { FooterInfo, H4 } from '../ui/Typography';
export const NewsArticle: FC<Props> = ({ article }) => {
  const { locale } = useRouter();
  const formattedDate = useMemo(() => new Date(article.date).toLocaleDateString(locale ?? 'en'), [article]);

  return (
    <Container href={article.url.fields.url}>
      <div style={{ position: 'relative' }}>
        <Image image={article.publisherLogo} />
        <FooterInfo as="span">{formattedDate}</FooterInfo>
      </div>
      <H4 as="h3">{article.title}</H4>
    </Container>
  );
};

type Props = {
  article: NewsCard;
};

const Container = styled(OutboundLink)`
  padding-block: clamp(1.85rem, 1.3rem + 2.4vw, 3.5rem);
  border-bottom: 1px solid ${({ theme }) => theme.color.accent.gray};

  display: block;

  > * {
    display: block;
  }

  > * + * {
    margin-top: 1rem;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    ${breakpointFrom.md`
      flex-direction: column-reverse;
      align-items: flex-start;
      justify-content: flex-start;
    `}
  }

  img {
    max-width: 10rem;
    max-height: 3rem;
    width: auto;
    object-fit: contain;
  }

  h3 {
    color: ${({ theme }) => theme.color.teal.dark};
  }
`;
