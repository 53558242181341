/** @format */

import { Image } from '@/components/Image';
import { ContainerRightSide, ContainerWrapper } from '@/components/ui/Layout';
import { RichText } from '@/components/ui/RichText';
import { Kicker } from '@/components/ui/Typography';
import { useMediaQueryIndex } from '@/hooks/useMediaQuery';
import { Document } from '@contentful/rich-text-types';
import { Asset } from 'contentful';
import { FunctionComponent as FC, useContext } from 'react';
import { breakpointUpTo } from 'src/theme/mixins';
import { FindColor } from 'src/utils/findThemeColor';
import styled, { DefaultTheme, ThemeContext } from 'styled-components';

export const HeaderLarge: FC<Props> = ({
  heroBackgroundColor = 'teal.dark',
  pageName,
  type,
  title,
  image,
  mobileImage,
}) => {
  const theme = useContext(ThemeContext);
  const mediaQueryIndex = useMediaQueryIndex();
  const mobile = !!(mediaQueryIndex !== null && mediaQueryIndex < 3);

  return (
    <ContainerWrapper $color={FindColor(heroBackgroundColor, theme?.color)}>
      <ContainerRightSide>
        <Wrapper $type={type}>
          <HeaderText>
            <CustomKicker as="p">{pageName}</CustomKicker>
            {typeof title === 'string' && <h1>{title}</h1>}
            <CustomStyleRichText>{typeof title !== 'string' && <RichText text={title} />}</CustomStyleRichText>
          </HeaderText>
          {mediaQueryIndex !== null && mediaQueryIndex > 3 && <div></div>}
          <HeaderImage $type={type}>
            <Image image={image} priority placeholder={false} />
            {mobile && mobileImage && <Image image={mobileImage} priority placeholder={false} />}
          </HeaderImage>
        </Wrapper>
      </ContainerRightSide>
    </ContainerWrapper>
  );
};

type Props = {
  type?: string;
  pageName: string;
  title: string | Document;
  image: Asset;
  mobileImage?: Asset;
  heroBackgroundColor?: string;
};

const Wrapper = styled.section<{ $type?: string }>`
  display: grid;
  min-height: 44rem;
  max-width: 1680px;
  position: relative;
  grid-template-columns: minmax(30.5rem, 36.75rem) minmax(34.75rem, 1fr);

  ${breakpointUpTo.xl`
    max-height: 100%;
    padding-bottom:4rem;
    overflow:visible;
    & > div:nth-child(1){
      margin-top: -10%;
        grid-row: 2/2;
        z-index:1;
    }
    grid-template-columns: 1fr;
    grid-template-rows: minmax(29.375rem,1fr) auto;
  `}

  color: ${({ theme }) => theme.color.white};
`;

const HeaderText = styled.div`
  z-index: 1;
  padding-top: clamp(6rem, 15.4933rem + -6.5926vw, 9.56rem);

  ${breakpointUpTo.md`
    padding-top:0rem;
    padding-bottom:0rem;
  `}
  color: ${({ theme }) => theme.color.white};
`;

const CustomKicker = styled(Kicker)`
  position: relative;
  padding-bottom: 3.19rem;

  ${breakpointUpTo.xl`
    max-width: 8.4375rem;
    position: absolute;
    top: 1.3rem;
  `}

  &:after {
    content: '';
    margin-top: 0.75rem;
    display: block;
    width: 2.875rem;
    height: 0.10625rem;
    background-color: ${({ theme }) => theme.color.yellow};
  }
`;

const CustomStyleRichText = styled.div`
  color: ${({ theme }) => theme.color.white};
  h1 {
    max-width: 36.75rem;
    white-space: pre-line;
    ${breakpointUpTo.lg`
      padding-right: ${({ theme }: { theme: DefaultTheme }) => theme.container.smallPadding};
    `}
    ${breakpointUpTo.sm`
      white-space: normal;
    `}
  }
  p,
  h6 {
    max-width: 33.75rem;
    color: ${({ theme }) => theme.color.white};
    ${breakpointUpTo.lg`
    padding-right: ${({ theme }: { theme: DefaultTheme }) => theme.container.smallPadding};
    `}
  }
`;

const HeaderImage = styled.div<{ $type?: string }>`
  --padding: ${({ theme }) => theme.container.smallPadding};
  position: absolute;
  z-index: 0;
  right: 0;
  max-width: clamp(38rem, -33.5rem + 98vw, 52.25rem);
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;

  ${breakpointUpTo.xl`
  position: relative;

  ${({ $type = '' }) => {
    switch ($type) {
      case 'work-with-us':
        return `left: calc(var(--padding) * -1);`;
      case 'real-estate-management':
        return ``;
      case 'real-estate-investments':
        return ``;
      default:
        return `left: calc(var(--padding) * -1);`;
    }
  }}
    max-width:calc(100% + var(--padding));
    width:calc(100% + var(--padding));
    overflow:visible;
    > img:first-child:not(:only-child) {
    opacity: 0;
    z-index: -1;
    }
  `}
  ${breakpointUpTo.lg`
   ${breakpointUpTo.xl`
        ${({ $type = '' }) => {
          switch ($type) {
            case 'work-with-us':
              return `
              width: 80% !important;`;
            case 'real-estate-management':
              return `
              `;
            case 'design-build':
              return `
              `;
            default:
              return `
              width: 80% !important;`;
          }
        }}
    `}
  `}
  ${breakpointUpTo.md`
   width: calc(100% + var(--padding)) !important;
    `}

  img {
    bottom: 0;
    height: auto;
    right: 0;
    top: 0rem;
    transform: translate(0, 0);
    margin: 0;
    padding: 0;
    position: absolute;
    object-fit: cover;
    width: auto;
    height: 100%;

    ${breakpointUpTo.xl`
        ${({ $type = '' }) => {
          switch ($type) {
            case 'work-with-us':
              return `
              width: 100% !important;
              top: 115%;
              transform: translate(0%,-90%);
              height:130% !important;
              object-position: top;`;
              break;
            case 'real-estate-management':
              return `
              width: 100%;
              height: auto;
              `;
              break;
            case 'real-estate-investments':
              return `
              width: 100%;
              height: 118%;
              object-position: top;
              `;
              break;
            case 'design-build':
              return `
              width: 100%;
              height: 118%;
              `;
              break;
            default:
              return `
              width: 100% !important;
              top: 115%;
              transform: translate(0%,-90%);
              height:130% !important;`;
              break;
          }
        }}
    `}
    ${breakpointUpTo.lg`
        ${({ $type = '' }) => {
          switch ($type) {
            case 'work-with-us':
              return `
              right: -8rem;
              width: 140% !important;
              top: 115%;
              transform: translate(12%,-90%);
              height:130% !important;
              object-position: top;`;
              break;
            case 'real-estate-management':
              return `
              right: unset;
              left: 0;
              width: 110%;
              height: auto;
              `;
              break;
            case 'real-estate-investments':
              return `
              right: unset;
              left: 0;
              width: 110%;
              height: 118%;
              `;
              break;
            case 'design-build':
              return `
              right: unset;
              left: 0;
              width: 110%;
              height: 116%;
              `;
              break;
            default:
              `
              right: -8rem;
              width: 140% !important;
              top: 115%;
              transform: translate(12%,-90%);
              height:130% !important;
              `;
              break;
          }
        }}
    `}
    ${breakpointUpTo.md`
        ${({ $type = '' }) => {
          switch ($type) {
            case 'work-with-us':
              return `
              right: 0;
              width: 100%!important;
              object-position: top;
              top: 90%;
              transform: translate(0%,-90%);
              height:100% !important;
              `;
            case 'real-estate-management':
              return `
              right: unset;
              left: 0;
              width: 110%;
              height: auto;
              `;
            case 'real-estate-investments':
              return `
              right: unset;
              left: 0;
              width: 110%;
              height: 100%;
              `;
            case 'design-build':
              return `
              right: unset;
              left: 0;
              width: 110%;
              height: 80%;
              `;
            default:
              return `right: 0;
              width: 100%!important;
              top: 90%;
              transform: translate(0%,-90%);
              height:100% !important;
              `;
          }
        }}

    `}

    ${breakpointUpTo.sm`
        ${({ $type = '' }) => {
          switch ($type) {
            case 'work-with-us':
              return `
              right: 0;
              width: 100%!important;
              object-position: top;
              top: 90%;
              transform: translate(0%,-90%);
              height:100% !important;
              `;
              break;
            case 'real-estate-management':
              return `
              width: auto;
              height: 100%;
              right: unset;
              left: 0;
              `;
              break;
            case 'real-estate-investments':
              return `
              width: auto;
              height: 100%;
              right: unset;
              left: 0;
              `;
              break;
            case 'design-build':
              return `
              width: auto;
              height: 105%;
              right: unset;
              left: 0;
              top: -10px;
              `;
              break;
            default:
              `right: 0;
              width: 100%!important;
              top: 90%;
              transform: translate(0%,-90%);
              height:100% !important;
              `;
              break;
          }
        }}

    `}
  }
`;
