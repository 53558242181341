/** @format */

import { Prefix } from '@/types/shared';
import { createContext } from 'react';

interface PrefixContext {
  isValid: boolean;
  data?: Prefix[];
  errorMessage?: string;
}

export const prefixContext: PrefixContext = {
  isValid: false,
  errorMessage: 'apiTryAgainError',
};

export const PrefixContext = createContext(prefixContext);
