/** @format */

import { FunctionComponent as FC, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useMediaQueryIndex } from '../../hooks/useMediaQuery';
import { breakpointFrom } from '../../theme/mixins';
import { NewsCard } from '../../types/news';
import { H4 } from '../ui/Typography';
import { NewsArticle } from './NewsArticle';
import { Pagination } from './Pagination';

const ARTCILES_PER_PAGE = 5;

export const NewsSection: FC<Props> = ({ articles, label }) => {
  const mediaQueryIndex = useMediaQueryIndex();

  const mobile = useMemo(() => !!(mediaQueryIndex !== null && mediaQueryIndex < 2), [mediaQueryIndex]);

  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + ARTCILES_PER_PAGE;
  const currentArticles = articles.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(articles.length / ARTCILES_PER_PAGE);

  const handlePageClick = (event: any) => {
    ARTCILES_PER_PAGE;
    const newOffset = (event.selected * ARTCILES_PER_PAGE) % articles.length;
    setItemOffset(newOffset);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <News>
      {label && <H4 as="h2">{label}</H4>}
      {currentArticles.map(article => (
        <NewsArticle key={article.url.fields.url} article={article} />
      ))}
      <Pagination onPageChange={handlePageClick} pageCount={pageCount} centered={mobile} />
    </News>
  );
};
type Props = {
  articles: NewsCard[];
  label?: string;
};

const News = styled.div`
  --border-color: ${({ theme }) => theme.color.accent.gray};
  display: grid;
  align-content: start;
  grid-template-columns: minmax(0, 1fr);
  margin-top: 3.75rem;

  ${breakpointFrom.md`
      padding-left: 1.94rem;
      border-left: 1px solid var(--border-color);
      margin-top: 0;
      padding-top: 0;
    `}

  h2 {
    --text-divider-gap: 1.88rem;
    --text-divider-color: ${({ theme }) => theme.color.accent.pink};

    color: ${({ theme }) => theme.color.pink};
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;

    &::before,
    &::after {
      content: '';
      height: 2px;
      background: var(--text-divider-color);
      flex-grow: 1;
    }

    &::before {
      margin-right: var(--text-divider-gap);
      ${breakpointFrom.md`
        display: none;
      `}
    }

    &::after {
      margin-left: var(--text-divider-gap);

      ${breakpointFrom.md`
        content: ":";
        height: unset;
        background: none;
        flex-grow: 0;
        display: inline;
        margin-left: 0;
      `}
    }
  }
`;
